import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"

import styled from "styled-components"
import { Container, Col, Row } from "react-bootstrap"

import Navbar from "../components/navbars/courseLandingNavbar"

import SearchEngine from "../components/SearchEngine"
import Layout from "../components/layouts/courseLandingLayout"

const ArticlesContainer = styled(Container)`
  margin-top: 120px;
`

const Articles = styled.div`
  padding-top: 80px;
  max-width: 800px;
  margin: 0 auto;
`

const TitleRow = styled(Container)`
  text-align: center;

  .title {
    padding-top: 80px;
    padding-bottom: 20px;
  }
`

const Blog = ({ data, location }) => {
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location}>
      <Navbar location={location} article={true} />
      <ArticlesContainer>
        <SearchEngine title="All posts" />

        <TitleRow>
          <Col md={12}>
            <h1 className="title">All Articles</h1>
            <p className="subtitle">
              Because we believe that <em>who you are</em> is the largest
              variable to your success, we write articles related to cultivating
              the necessary skills and character to change careers and become a
              software engineer.
              <br></br>
              <br></br>Topics include productivity, personal development,
              entrepreneurship and more. If you have a topic you'd like us to
              cover, please reach out to brian@parsity.io{" "}
              <a
                target="_blank"
                href="https://www.instagram.com/brianjenneycode/"
              >
                Brian Jenney
              </a>
              .
            </p>
          </Col>
        </TitleRow>

        <Articles>
          {posts.map(({ node }, i) => {
            const title = node.frontmatter.title || node.fields.slug

            const renderHR = () => {
              if (i + 1 !== posts.length) {
                return <hr></hr>
              }
            }

            return (
              <>
                <article key={node.fields.slug}>
                  <header>
                    <h5>
                      <Link to={node.fields.slug}>{title}</Link>
                    </h5>
                  </header>
                </article>
                {renderHR()}
              </>
            )
          })}
        </Articles>
      </ArticlesContainer>
    </Layout>
  )
}

export default Blog

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            description
          }
        }
      }
    }
  }
`
